@font-face {
  font-family: "Regular";
  src: local("Articulat CF"), url(./fonts/Regular.otf) format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Bold";
  src: local("Articulat CF"), url(./fonts/Bold.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "DemiBold";
  src: local("Articulat CF"), url(./fonts/DemiBold.otf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Medium";
  src: local("Articulat CF"), url(./fonts/Medium.otf) format("opentype");
  font-weight: 500;
}

.toast-body {
  background: var(--theme-ui-colors-box);
}

/* HACK: Fix title color in wallet modal */
.tw-font-medium {
  color: black;
}

/* HACK: Fix title color in wallet modal */
.tw-text-lg {
  color: black;
}
